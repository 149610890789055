import { Injectable, ViewChild, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  BOT_TOKEN_INFO,
  USER_INFO,
  USER_TOKEN_INFO,
  IS_CUSTOM_LOGIN,
} from '@algomusinc/domain';
import {
  AuthService,
  LocalstorageService,
  LoaderService,
  SnackbarService,
  ConfigurationService,
} from '@algomusinc/services';
import { environment } from '../environments/environment';
import { OktaAuthService } from '@algomusinc/okta';
import { SnackbarComponent } from '@algomusinc/components';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private oktaAuthService!: OktaAuthService;
  constructor(
    public storage: LocalstorageService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public authService: AuthService,
    public snackbar: SnackbarService,
    private loadingBar: LoadingBarService,
    public config: ConfigurationService,
    private injector: Injector
  ) {
    if (environment.okta.enableOkta) {
      this.oktaAuthService = <OktaAuthService>(
        this.injector.get(OktaAuthService)
      );
    }
  }
  public hideLoaderList: string[] = [
    `${environment.AppFBSpreading}/SpreadingForecastAddCommitMeasures`,
  ];
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let token: any = this.storage.get(USER_TOKEN_INFO);
    const isCustomLogin = this.storage.get(IS_CUSTOM_LOGIN);
    if (isCustomLogin) {
      token = this.storage.get(USER_TOKEN_INFO);
    } else if (this.oktaAuthService) {
      token = this.oktaAuthService.oktaAuth.getAccessToken();
    }
    if (token) {
      if (request.url === environment.AppTokenGenerate) {
        request = request.clone({
          headers: request.headers.set(
            'Authorization',
            'Bearer ' + environment.BotSecretKey
          ),
        });
      } else if (
        request.url.startsWith(environment.AppConversations) ||
        request.url === environment.AppTokenRefresh
      ) {
        const conversatonToken: string = this.storage.get(BOT_TOKEN_INFO);
        request = request.clone({
          headers: request.headers.set(
            'Authorization',
            'Bearer ' + conversatonToken
          ),
        });
      } else if (
        request.url !==
        `https://login.microsoftonline.com/dd70a646-992f-4f0e-a15e-d9c554e1f576/oauth2/v2.0/token`
      ) {
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token),
        });
      }
    }
    if (
      !(
        request.url === `${environment.AppDocument}/UploadFiles` ||
        request.url === `${environment.AppFBForcast}/Import` ||
        request.url === `${environment.AppFBForcast}/ImportVerticalLayout` ||
        request.url === `${environment.AppCommonCode}/DocumentAdd` ||
        request.url === `${environment.GenAiapi}/ask` ||
        request.url === `${environment.GenAiapi}/process_csv` ||
        request.url === `${environment.GenAiapi}/process_link` ||
        request.url === `${environment.GenAiapi}/get_col_description`
      )
    ) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          headers: request.headers.set('Content-Type', 'application/json'),
        });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });
      request = request.clone({
        headers: request.headers.set('x-application-key', 'AdminPortal'),
      });
    } else {
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json'),
      });
    }
    const self = this;
    const ref = this.loadingBar.useRef('http');
    if (this.hideLoaderList.includes(request.url)) {
      ref.complete();
    } else {
      ref.start();
    }

    return next.handle(request).pipe(
      finalize(() => {
        ref.complete();
      }),
      catchError((error: any): any => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            localStorage.clear();
            window.location.reload();
            return throwError(() => error);
          } else if (
            error &&
            error.error &&
            error.error.error &&
            error.error.error.indexOf('Multiple planner') > -1
          ) {
            this.snackbar.error(
              SnackbarComponent,
              'A new planner session has been opened.',
              'Multiple Planner Operations'
            );
            this.config.updateForecastList.next(request);
          } else if (self.spinner) {
            self.spinner.hide();
          }
          return throwError(() => error);
        }
      })
    );
  }
}
