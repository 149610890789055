// The file contents for the current environment will overwrite these during build.
// The build system defaults to the ${env} environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json` hello.
// LOCAL
// appRootCommon: `https://localhost:44315/api`,
// appRootVehicle: `https://localhost:44311/api`,
// appRootInspectionCentre: `https://localhost:44375/api`,
// appRootAccount: `https://localhost:44346/api`,    vm

// const env = 'https://localhost:44356/api';
// const configuration = 'https://localhost:44315/api';

// const env = 'https://fbqa-acc-api.algoplus.com/api';
// const configuration = 'https://fbqa-config-api.algoplus.com/api';

const env = 'https://as-web-acc-api-algoplus-target-qa.azurewebsites.net/api';
const configuration = 'https://as-web-config-api-algoplus-target-qa.azurewebsites.net/api';
// const env = 'https://algoplusaccountapipt.azurewebsites.net/api';
// const configuration = 'https://algoplusconfigapipt.azurewebsites.net/api';
// const env = 'https://fbdev-acc-api.algoplus.com/api';
// const configuration = 'https://fbdev-config-api.algoplus.com/api';
//const accountplanning = 'https://algoplusjvaccountplanningapidemo.azurewebsites.net/api';
//const cpm = 'https://algopluscpmcpmconfigurationdev.azurewebsites.net/api';
const bot = `https://directline.botframework.com/v3/directline`;
// const notification = `https://algoplusnotificationdev.azurewebsites.net/api`;
// const notificationHub = `https://algoplusnotificationdev.azurewebsites.net`;
// const backgroundTask = `https://algoplusbackgroundtaskdev.azurewebsites.net/api`;

const amazonScraper = `https://localhost:7273/api`; // Replace path when banckend is deployed
const targetBaseUrl =
  'https://as-web-skylight-api-algoplus-target-qa.azurewebsites.net/api'; // Replace path when banckend is deployed
const forecastURL =
  'https://as-web-forecast-api-algoplus-target-qa.azurewebsites.net/api'; // Replace path when banckend is deployed

export const environment = {
  showHomeBase:false,
  enableSignalR: false,
  isTeamBasedMenus: false,
  isJWTaApiToken: false,
  enableJourney: true,
  showSaveSelections: true,
  notificationAlerts: false,
  NlPAutoComplete: false,
  okta: {
    enableOkta: false,
    addAuthorizationServer: true,
    OKTA_DOMAIN: `dev-30785815.okta.com`,
    CLIENT_ID: `0oa4gijuh14W3JVkn5d7`,
    CALLBACK_PATH: '/login/callback',
    AUTHORIZATION_SERVER: `/oauth2/default`,
    PROTOCOL: `https`,
    redirectUri: 'https://fbdev.algoplus.com/login/callback',
    changePassword: 'https://dev-30785815.okta.com/enduser/settings',
    logoutUri: 'https://fbdev.algoplus.com',
    homeUri: 'https://fbdev.algoplus.com',
    showAlgoLogoutPage: false,
    showChangePassword: false,
    isEnable: false,
  },
  agGridLicenseKey: `CompanyName=Algomus Inc,LicensedGroup=Algo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-037124,SupportServicesEnd=18_March_2024_[v2]_MTcxMDcyMDAwMDAwMA==31baa4eb45af976d60368922cbd068c8`,
  headerLogoURL: '',
  headerLogoEarth: '../assets/images/earth.png',
  headerLogoEarthHeight:38,
  logoWidthEarth:45,
  digitaWallet_buttonName: 'open',
  loginLogoURL: '',
  logoWidth: 70,
  logoWidthLogin: 200,
  logoWidthForget: 200,
  microsoftURL: `https://login.microsoftonline.com/dd70a646-992f-4f0e-a15e-d9c554e1f576/oauth2/v2.0/token`,
  ClientId: 12,
  production: false,
  idleTime: 8,
  fusionChartsKey:
    ' qqA3ydjA5C5B3A5F5G4B3E3A10B5E2B2E2ixrG1C3gcowg1B3I4F-7mC-16B5B2D3D1G1F4H4B1C8A4D2G5G3C1G2B1ippB1A4B4i1A33A18B14utcB4A2H4moA-31A7C8A3D-11geH2C2C5A9B3B5F3G4F1C2F1zllC7E2f1G4i1dB-22C6D6B5jyD3RF1B5wwf1C2A3E2E3G3C9A6E4E4C4G3B2H2H4B-21==',
  firebaseConfig: {
    apiKey: 'AIzaSyAm_KeXGPwI_f04JUIMNWdjgRrbXIeS2ao',
    authDomain: 'algo-plus-pdm.firebaseapp.com',
    databaseURL: 'https://algo-plus-pdm.firebaseio.com',
    projectId: 'algo-plus-pdm',
    storageBucket: 'algo-plus-pdm.appspot.com',
    messagingSenderId: '527789555004',
    appId: '1:527789555004:web:c9d78934aec2bf0889b239',
    measurementId: 'G-KFZ1Q7G63H',
  },
  AccessToken: `${configuration}/PowerBI/GetAccessToken`,
  BotSecretKey: `Y8noDdyqI2w.aFhA-ZQYDoFjdVZr-uX59wVyobs6ls1J3ukfeH_1-8w`,
  mapApi: `AIzaSyCH4mv9Pw3dRWz0yUoMyf4IexqPNUBdzvw`,
  utcOffset: 180,
  AppTokenGenerate: `${bot}/tokens/generate`,
  AppTokenRefresh: `${bot}/tokens/refresh`,
  AppConversations: `${bot}/conversations`,
  AppAccount: `${env}/Account`,
  AppClaim: `${env}/Claim`,
  AppClaimGroup: `${env}/ClaimGroup`,
  AppRole: `${env}/Role`,
  AppCalender: `${configuration}/Calender`,
  AppSubscription: `${configuration}/Subscription`,
  AppUserPreference: `${configuration}/UserPreference`,
  AppTaskRequest: `${configuration}/TaskRequest`,
  AppTask: `${configuration}/Task`,
  AppJourney: `${configuration}/Journey`,
  AppJourneyLookUp: `${configuration}/JounreyLookUp`,
  AppJourneyCollaboration: `${configuration}/JourneyCollaboration`,
  AppJourneyContextLog: `${configuration}/JourneyContextLog`,
  AppJourneyContextLogComment: `${configuration}/JourneyContextLogComment`,
  AppJourneyInsightComments: `${configuration}/JourneyInsightComments`,
  AppJourneyUserActivityLog: `${configuration}/JourneyUserActivityLog`,
  AppPowerBi: `${configuration}/PowerBi`,
  AppCategory: `${configuration}/Category`,
  AppChannel: `${configuration}/Channel`,
  AppClient: `${configuration}/Client`,
  AppDistributor: `${configuration}/Distributor`,
  AppForms: `${configuration}/Forms`,
  AppSource: `${configuration}/Sourcing`,
  AppGoal: `${configuration}/Goal`,
  AppGoalType: `${configuration}/GoalTypes`,
  AppGoalTenure: `${configuration}/GoalTenureTypes`,
  AppSourceMixForm: `${configuration}/SourceMixForm`,
  AppSourceMixRow: `${configuration}/SourceMixRow`,
  AppSourceMixHeader: `${configuration}/SourceMixHeader`,
  AppCategoryMixRow: `${configuration}/CategoryMixRow`,
  AppCategoryMixHeader: `${configuration}/CategoryMixHeader`,
  AppCategoryMixForm: `${configuration}/CategoryMixForm`,
  AppStoreMixRow: `${configuration}/NewFormRow`,
  AppStoreMixHeader: `${configuration}/NewFormHeader`,
  AppStoreMixForm: `${configuration}/NewForm`,
  AppSameStoreRow: `${configuration}/SameStoreRow`,
  AppSameStoreHeader: `${configuration}/SameStoreHeader`,
  AppSameStoreForm: `${configuration}/SameStoreForm`,
  AppCalenderActivities: `${configuration}/CalendarActivity`,
  AppEventTypes: `${configuration}/EventTypes`,
  AppActionItems: `${configuration}/ActionItems`,
  AppChannelSourceWise: `${configuration}/ChannelSourceWise`,
  AppMenu: `${configuration}/Menu`,
  AppAnnouncement: `${configuration}/Announcement`,
  AppNote: `${configuration}/Note`,
  AppAPBudgetType: ``,
  AppPromotion: ``,
  AppSKU: ``,
  AppSKUPromotion: `Promotion`,
  AppDecayCurve: ``,
  AppItemMaster: ``,
  AppBussinessAttribute: ``,
  AppBussinessInformation: ``,
  AppWorkgroup: ``,
  AppLevel: ``,
  AppUserLevel: ``,
  UploadDoc: ``,
  //UploadDoc: `https://localhost:44399/api/AnomalyDetector/Upload`,
  Anomoly: ``,
  AnomolyFiles: ``,
  SyncPowerBIReports: `${configuration}/PowerBI/GetReportList`,
  NotificationCommon: ``,
  NotificationHub: ``,
  ApplicationInsights: `${configuration}/ApplicationInsights`,
  AppDocument: ``,
  AppCommonCode: `${configuration}/CommonCode`,
  AppSuggestAdmin: ``,
  AppCurrency: `${configuration}/CurrencySetting`,
  AppTeams: `${env}/Team`,
  AppFBSpreading: `${configuration}/Spreading`,
  AppCurrencyRate: `${configuration}/CurrencyRate`,
  AppBuyerSetting: `${configuration}/BuyerSetting`,
  AppFinanceSetting: `${configuration}/FinancialSetting`,
  AppProductSetting: `${configuration}/ProductSetting`,
  AppFBForcast: `${configuration}/FBForcast`,
  AppItemManager: `${configuration}/ItemManager`,
  AppTeamScreenGroup: `${configuration}/TeamScreenGroup`,
  AppItemForcast: `${configuration}/ItemForecast`,
  AppAccountSettingMapping: `${configuration}/AccountSettingMapping`,
  AppProductSettingMapping: `${configuration}/ProductSettingMapping`,
  FbForecastProfile: `${configuration}/FBMeasureProfile`,
  MsForecastProfile: `${configuration}/MSMeasureProfile`,
  PromotionPlanner: `${configuration}/PromotionPlanner`,
  OrderList: `${configuration}/MSOrderList`,
  AppBulkCommit: `${configuration}/BulkCommitJob`,
  maxPlannerSaveCount: 20,
  Ess: '',
  autoCompleteApi: 'https://autocomplete-api-demo.azurewebsites.net',
  GenAiapi: `https://questionanswer-gen-ai-api-demo.azurewebsites.net`,
  AmazonScraperBuyBox: `${amazonScraper}/BuyBox`,
  AmazonScraperRequest: `${amazonScraper}/ScraperRequest`,
  SkylightForecast: `${targetBaseUrl}/Forecast`,
  TargetBaseUrl: `${targetBaseUrl}`,
  outlierManager: `${forecastURL}/OutlierManager`,
  MappingManager: `${forecastURL}/MappingManager`,
  PromoManager: `${forecastURL}/PromoManager`,
  SeasonalityManager: `${forecastURL}/SeasonalityManager`,
  ForecastManager: `${forecastURL}/ForecastManager`,
  ForecastMapping: `${forecastURL}/Lookup`,
  KPIList: `${configuration}/KPI`,
  CoreArea: `${configuration}/CoreArea`,
  KPISubscription: `${configuration}/KPISubscription`,
  KPISlicer: `${configuration}/KPISlicer`,
  GlobalEntity: `${configuration}/Global`,
  Homebase: `${configuration}/HomeBase`,
};
